import { SessionProvider } from '@lendi/lala-react';
import { SimpleNavbar } from '@lendi/navbar';
import getColour from '@lendi-ui/commons/colours';
import ThemeProvider from '@lendi-ui/theme';

import { ToolCard } from './components';
import { getBrand, getBrandTools, getEnv, getSession, getBaseUrl } from './utils';
import { Text } from '@lendi/ui';
import React from 'react';

export default function App() {
  const brand = getBrand();
  const env = getEnv();
  const session = getSession(brand, env);
  const baseUrl = getBaseUrl(brand, env);
  const tools = getBrandTools(brand);

  return (
    <SessionProvider session={session}>
      <ThemeProvider name={brand}>
        <main className="min-h-screen" style={{ backgroundColor: getColour('background', 'page') }}>
          <SimpleNavbar
            homeURL={baseUrl}
            showBookanAppointmentCta={false}
            showContinueAction={false}
            showTalkToExpertCta={false}
            onException={console.error}
          />
          <div className="mx-auto py-9 px-4 flex w-full max-w-[1200px] flex-col gap-12">
            {tools.map(({ key, category, items }) => (
              <React.Fragment key={key}>
                <div className="flex w-full flex-col gap-3 items-center sm:items-start">
                  <Text variant="title1" tag="h3">
                    {category}
                  </Text>
                  <div className="flex flex-col sm:flex-row gap-6 items-center self-stretch flex-wrap">
                    {items.map((tool) => (
                      <ToolCard key={tool.key} tool={tool} />
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </main>
      </ThemeProvider>
    </SessionProvider>
  );
}
