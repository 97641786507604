import { Text } from '@lendi/ui';

import type { Tool } from '../utils';
import getColour from '@lendi-ui/commons/colours';

type ToolCardProps = {
  tool: Tool;
};

export default function ToolCard({ tool }: ToolCardProps) {
  const { label, Icon, url } = tool;
  return (
    <div
      className="flex flex-col w-[208px] h-[208px] p-6 gap-3 items-center justify-center rounded-md shadow-[0_1px_3px_0_rgba(0,0,0,0.14),0_2px_2px_0_rgba(0,0,0,0.12),0_0_2px_0_rgba(0,0,0,0.12)] cursor-pointer"
      style={{ backgroundColor: getColour('background', 'container') }}
      onClick={() => window.open(url, '_blank')}
    >
      <Icon width="36px" height="36px" color={getColour('interaction', 'main')} />
      <Text tag="p" variant="subtitle1" align="center" className="w-[160px] h-[48px] whitespace-pre-line">
        {label}
      </Text>
    </div>
  );
}
